import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridFilterInputSingleSelect
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import getAccess from "../utils/getAccess";
import { useTheme } from "@emotion/react";
import * as XLSX from 'xlsx'

const removeComma = (array) => {
  return array.reduce((acc, curr) => acc + " " + curr, "")
};



function CustomToolbar({ rows, columns, loading, setLoading, searchText, setSearchText, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'serialNumber') {
            return index + 1;
          }
          else if (columnId === 'Stream') {
            return removeComma(row.Stream)
          }
          return row[columnId]?.toString().replaceAll(",", " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Users.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function DashboardGrid() {
  const { type } = useParams();
  const d = getAccess();
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rowCountState, setRowCountState] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25
  })
  const [loading, setLoading] = React.useState(true);
//   const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef({});
  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
    }),
    [paginationModel],
  );
  

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    let page = parseInt(queryOptions.cursor) + 1;
    let pageSize = parseInt(queryOptions?.pageSize) 
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/dashBoardGrid?type=${type}&page=${page}&pageSize=${pageSize}&searchText=${searchText}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
    //   console.log(responseJson?.data)
      setRows(responseJson.data?.response);
      setRowCountState(responseJson.data?.count);
      setLoading(false);
    }
    fetchUsers();
    // let fetch =  setTimeout(fetchUsers, 500) ;
    // return () => clearTimeout(fetch)

  }, [queryOptions, searchText]);

  const handlePaginationModelChange = (newPaginationModel) => {
    // We have the cursor, we can allow the page transition.
    // console.log("handle")
    if (newPaginationModel.page === 0 || mapPageToNextCursor.current[newPaginationModel.page - 1]) {
      setPaginationModel(newPaginationModel);
      // console.log("handle2")
    }
  };


  React.useEffect(() => {
    if (!loading) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = paginationModel.page + 1;
    }
  }, [paginationModel.page, loading]);

  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  // const debounceHandleChange = debounce(handleChange , 500);

  

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "batchName",
      headerName: "Batch Name",
      type: "string",
      width: 400,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "studentName",
      headerName: "Student Name",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ?? "NA"}</div>
      ),
    },
    {
      field: "studentEmail",
      headerName: "Student Email",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "studentPhone",
      headerName: "Phone No",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },

    {
      field: "amount",
      headerName: "Amount",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {/* <Box m={2}>
        <TextField value={searchText} onChange={handleChange} placeholder="Search Text ....." />
      </Box> */}
       
        <Box m={2}>
          { type == "soldBatches" && <h2>Sold Batches Details </h2>}
          { type == "todayDue" && <h2>Today Due Details </h2>}
          { type == "nextMonthDue" && <h2>Next Month Due Details </h2>}
          { type == "overDue" && <h2>Over Due Details </h2>}
        </Box>
      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        editMode="row"
        loading={loading}
        rowCount={rowCountState}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} loading={loading} setLoading={setLoading} searchText={searchText} setSearchText={setSearchText} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
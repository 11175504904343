// component
import { useContext } from "react";
import Iconify from "../../components/Iconify";
import getAccess from "../../utils/getAccess";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const assignBatch = localStorage.getItem("assignBatch");

// it include access number for nav Section

let d = getAccess();
let role = d?.role;

// I -  DashBoard

// II -  Users ( main Heading)  ->    1
// subHeading of users
// i - All Users    -- 1111
// (ii) - Courses Purchasees  --- 1112
// (iii) - Test Series Purchase    --- 1113
// (iv) - Purchase Report   ---- 1114
// (v) - user RePort    --- 1115
// vi - Call To Action   --- 1116
// vii webSiteSearch ---- 1117

let usersHeading = {}; // ---- 1
if (
  d?.access?.includes("all") ||
  d?.access?.includes("1111") ||
  d?.access?.includes("1112") ||
  d?.access?.includes("1113") ||
  d?.access?.includes("1114") ||
  d?.access?.includes("1115") ||
  d?.access?.includes("1116") ||
  d?.access?.includes("1117")
) {
  usersHeading = {
    title: "Users",
    path: "#",
    icon: getIcon("eva:people-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
// subHeading of users
// i - All Users    -- 1111
let allUsersSubHeading = {};
if (d?.access?.includes("all") || d?.access?.includes("1111")) {
  allUsersSubHeading = {
    title: "All Users ",
    path: "/dashboard/students",
    navicon: getIcon("icon-park-twotone:data-user"),
    cName: "sub-nav",
  };
}
let navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  {
    ...usersHeading,
    subNav: [
        {
           ...allUsersSubHeading,
        },
    ],
  },
];

export default navConfig;

// ----------------------------------------------------------------------
const name = localStorage.getItem("details")
const role = localStorage.getItem("role")
const account = {

  displayName: name,
  // email: 'rahul@gmail.com',
  role: role,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;

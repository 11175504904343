// @mui
import { Grid } from "@mui/material";
// components
import Page from "../components/Page";
import {
  Container,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import getAccess from "../utils/getAccess";
import { AppWidgetSummary1 } from '../sections/@dashboard/app'
import DashboardGrid from "./DashboardGrid";
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";

const HoverGrid = styled(Grid)({
  '&:hover': {
    background: 'red',
    cursor: 'pointer',
  },
});

// ----------------------------------------------------------------------

export default function DashboardApp() {
  
  const[counts , setCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const d = getAccess();
  const [ type , setType ] = useState('soldBatches');
  // 1 -soldBatches
  // 2 - todayDue 
  // 3 - nextMonthDue 
  // 4 - overDue 
  const role = d?.role == 'admin' ?  'Admin' :  d?.role == 'teacher' ?   'Teacher' : d?.role;
  // const teacherId = localStorage.getItem("id");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {

      // fetch count
      const fullResponse1 = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSalePanelDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson1 = await fullResponse1.json();
      setCounts(responseJson1.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        {/* <Typography variant="h3" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}

        <Grid container spacing={3}>
          {/* <Grid item xs={10} sm={5.2} md={2.2} onClick={ () => setType('soldBatches')}>
           */}
          <Grid item xs={10} sm={5.2} md={2.2} onClick={ () => navigate(`/dashboard/details/${'soldBatches'}`)}>

              <AppWidgetSummary1
               title="No Of Sale Batches"
                // total={batches?.length}
                total={counts?.totalSaleBatch}
                icon={"eva:shopping-bag-fill"}
              />
          </Grid> 
          <Grid item xs={10} sm={5.5} md={2.2} onClick={ () => navigate(`/dashboard/details/${'soldBatches'}`)}>
             <AppWidgetSummary1
                title="Sale Amount"
                total={counts?.totalAmount}
                // link={}
                // total={counts.batchCount}
                icon={"eva:shopping-bag-fill"}
              />
          </Grid>

          <Grid item xs={10} sm={5.5} md={2.2} onClick={ () => navigate(`/dashboard/details/${'todayDue'}`)}>
              <AppWidgetSummary1
                title="Today Due"
                // total={users?.length}
                total={counts?.todayDue}
                color="info"
                icon={"eva:people-fill"}
              />
          </Grid>

          <Grid item xs={10} sm={5.5} md={2.2} onClick={ () => navigate(`/dashboard/details/${'nextMonthDue'}`)}>
              <AppWidgetSummary1
                title="Next Month Due"
                // total={teachers?.length}
                total={counts?.nextMonthDue}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
          </Grid>

          <Grid item xs={11} sm={5.5} md={2.2} onClick={ () => navigate(`/dashboard/details/${'overDue'}`)}>
            <AppWidgetSummary1
              title="Over Due"
              color="error"
              total={counts?.overDue}
              icon={"eva:file-text-fill"}
            />
          </Grid>
        </Grid>
      </Container>
      
    </Page>
  );
}

import { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { User } from "../../context/context";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import MessageIcon from "@mui/icons-material/Message";
import { Box, SpeedDial } from "@mui/material";
import Page404 from "../../pages/Page404";
import Fab from "@mui/material/Fab";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  zIndex: 1,
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const LoggedIn = localStorage.getItem("isLoggedIn");
  const tokenJwt = localStorage.getItem("token");
  if (tokenJwt) {
    const decodeJwt = JSON.parse(atob(tokenJwt.split(".")[1]));
    if (decodeJwt.exp * 1000 < Date.now()) {
      //console.log("JWT expired");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("details");
      localStorage.removeItem("role");
      setTimeout(() => navigate("/"), 1000);
    } else {
      // //console.log('not expired');
    }
  } else {
    //console.log("TOken not found one");
  }
  const {
    role,
    showBackgroundPercentage,
    setShowBackgroundPercentage,
    percentage,
  } = useContext(User);
  const actions = [
    { icon: <FileCopyIcon />, name: "Copy" },
    { icon: <SaveIcon />, name: "Save" },
    { icon: <PrintIcon />, name: "Print" },
    { icon: <ShareIcon />, name: "Share" },
  ];

  return (
    <>
      {LoggedIn ? (
        <RootStyle>
          <Box
            sx={{
              top: "15%",
              right: "5%",
              zIndex: "999",
              transform: "translateZ(0px)",
              position: "fixed",
              flexGrow: 1,
            }}
          >
            {showBackgroundPercentage && (
              <Fab
                variant="extended"
                size="small"
                color="error"
                aria-label="add"
              >
                {/* <NavigationIcon sx={{ mr: 1 }} /> */}
                {percentage < 100 ? `${percentage}% Upload` : "Uploaded"}
              </Fab>
            )}
          </Box>
          <Box
            sx={{
              bottom: "5%",
              right: "5%",
              zIndex: "999",
              transform: "translateZ(0px)",
              position: "fixed",
              flexGrow: 1,
            }}
          ></Box>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle>
            <Outlet />
          </MainStyle>
        </RootStyle>
      ) : (
        <Page404 from="JWT Expired" />
      )}
    </>
  );
}

import { Box, Grid, Pagination, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import Page from '../../../components/Page'
import Page from '../components/Page'
// import "./students.css"
import StudentCard from './StudentCard';
import { useParams } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import Batchs from './Batchs';

const StudentProfile = () => {

    const theme = useTheme();
     //Id
    const { data } = useParams()



    //Tabs 
    const [valueForTabs, setValueForTabs] = React.useState(0);
    const[quizs , setQuizs] = React.useState();
    //Fetching Data
    const [users, usersSet] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/studentDetail/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setLoading(false)
            usersSet(responseJson.data);
        }

        fetchUsers();
    }, []);




    

    //Test Series
    //Getting Details
    const [loading, setLoading] = useState(true)
    const [testSeries, setTestSeries] = useState()
    const [test, setTest] = useState();
    const[webSitePayments , setWebSitePayments] = useState();
    const[storePayments , setStorePayments] = useState();
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTestSeriesPurchased/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTestSeries(responseJson.data);
            const fullResponseTest = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAttemptedTestAdminPanelSide?userId=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJsonTest = await fullResponseTest.json();
            setTest(responseJsonTest);
            const fullResponseQuiz = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAttemptedQuizsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonQuiz = await fullResponseQuiz.json();
            setQuizs(responseJsonQuiz?.data ?? []);
            // website paymnets for user
            const fullResponseWebsite = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getWebSitePaymentsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonWebsite = await fullResponseWebsite.json();
            // store Payments for user
            setWebSitePayments(responseJsonWebsite?.data ?? []);
            const fullResponseStore = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getStorePaymentsByUserId?id=${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJsonStore = await fullResponseStore.json();
            setStorePayments(responseJsonStore?.data ?? []);
            
            setLoading(false)
            if (responseJson.msg === 'not an admin') {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("details")
            };
        }

        fetchUsers();
    }, []);


    //Timer
    //Getting Details

    const [timer, setTimer] = useState()
    // const [test, setTest] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTimerByStudentId/${data}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTimer(responseJson.data);

            setLoading(false)
            if (responseJson.msg === 'not an admin') {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("details")
            };
        }

        fetchUsers();
    }, []);
    //console.log(timer)

    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }} > <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack >) : 
        (<Page title='Student Profile'>
            <Grid container spacing={1} sx={{ height: '70vh' }}>
                <Grid item xs={12} sm={12} md={8} sx={{ height: '860px', overflow: 'auto' }}>
                    <Stack sx={{ height: '100%' }}>
                        <Batchs user={data}/>

                      
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{ height: '110vh' }}>
                    <StudentCard role='Student' detail={users?.userDetails} />
                </Grid>
            </Grid>

        </Page>)
        }</>
    )
}

export default StudentProfile
// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
// components
import Iconify from "../../../components/Iconify";
import { useState } from "react";
import "./style.css";
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

const dnone = {
  display: "none",
};

const dshow = {
  display: "show",
};

// ----------------------------------------------------------------------

AppWidgetSummary1.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  sx: PropTypes.object,
};

export default function AppWidgetSummary1({
  title,
  total,
  icon,
  color = "primary",
  sx,
  ...other
}) {
  // const [display,setDispaly] = useState("false");

  // const handleDisplay = () =>{
  //   setDispaly(!Display);

  // };


  const theme = useTheme();
  return (
    <Card
      className="Display_details"
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        color: theme.palette.primary.mainTheme,
        bgcolor: theme.palette.primary.mainThemeLight,
        ...sx,
        minHeight: '229px',
        '&:hover': { background: '#9933ff'  , cursor : 'pointer' }
      }}
      {...other}
    >
      {/* <IconWrapperStyle
        sx={{
          color: theme.palette.primary.mainTheme,
          width: '70px',
          bgcolor: theme.palette.secondary.mainThemeLight,
        }}
        className='icon'
      >
        <Iconify icon={icon} width={70} height={70} />
      </IconWrapperStyle> */}

      <Typography className="display_elements" variant="h3">
        {fShortenNumber(total ? total : 0)}
      </Typography>

      <Typography className="display_elements1" sx={{ color: theme.palette.primary.mainTheme }} variant="subtitle2">
        {title}
      </Typography>
    </Card>
  );
}

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

// import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------

// for ckeditor -->
//  1 ---- online bulider 
//  2 - download zip 
//  3 - npm add file:./ckeditor5
// 4 -> npm install @ckeditor/ckeditor5-react
export default function App() {
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_LIVE_URL;
  }, [])
  return (
    <ThemeProvider>
      <ScrollToTop />

      {/* <BaseOptionChartStyle /> */}
      <Router />
      <Toaster />
    </ThemeProvider>
  );
}

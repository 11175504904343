import * as React from "react";
import Box from "@mui/material/Box";
import axios from 'axios';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { Select, MenuItem } from '@mui/material';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
  GRID_DATE_COL_DEF,
  useGridApiContext,
  GRID_DATETIME_COL_DEF,
  getGridDateOperators,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import getAccess from "../utils/getAccess"
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputBase from '@mui/material/InputBase';
import { enUS as locale } from 'date-fns/locale';
import { styled } from '@mui/material/styles';

const dateAdapter = new AdapterDateFns({ locale });
// import getAccess from "../../../utils/getAccess";
// import getAccess from "../utils/getAccess"

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getGridDateOperators(false).map((item) => ({
    ...item,
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime: false },
  })),
  valueFormatter: (value) => {
    // console.log("49" , value)
    if (value && value != "") {
      const date = new Date(value.value)
      // console.log(date)
      return dateAdapter.format(date, 'keyboardDate');
    }
    return '';
  },
};

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0 9px',
});

function WrappedGridEditDateInput(props) {
  const { InputProps, focused, ...other } = props;
  return <GridEditDateInput fullWidth {...InputProps} {...other} />;
}

function GridEditDateCell({ id, field, value, colDef }) {
  const apiRef = useGridApiContext();
  console.log("72" , value)

  const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

  const handleChange = (newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Component
      value={value}
      autoFocus
      onChange={handleChange}
      // slots={{ textField: WrappedGridEditDateInput }}
      renderInput={(params) => <WrappedGridEditDateInput {...params} />}
    />
  );
}

function GridFilterDateInput(props) {
  const { item, showTime, applyValue, apiRef } = props;
  console.log("93" , item?.value)
  const Component = showTime ? DateTimePicker : DatePicker;

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Component
      value={item.value ? new Date(item.value) : null}
      autoFocus
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      slotProps={{
        textField: {
          variant: 'standard',
        },
        inputAdornment: {
          sx: {
            '& .MuiButtonBase-root': {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
}



function GridEditSelectCell(props) {
  const { id, field, value, colDef } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <Select
      value={value || ''}
      onChange={handleChange}
      autoFocus
      fullWidth
    >
      {colDef.editableOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}



export default function EmisTable() {
    const { myBatchId } = useParams();
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const navigate = useNavigate();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/saleEmiDetails/${myBatchId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data ?? []) ;
    
    }

    fetchUsers();
  }, []);

  const editDataInDb = async (newRow) => {
    const details = {
       installmentDate : newRow?.expectedDate,
       txnDate : newRow?.txnDate,
       amount : newRow?.txnAmount ,
       txnMode : newRow?.txnMode ,
       txnId : newRow?.txnId , 
       isPaid : newRow?.isPaid , 
   }
  //  console.log(newRow)

  // setLoading(true)

  const token = localStorage.getItem("token");
  const authToken = token;
  const config = {
      headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${authToken}`,
      },
  };
//   setProgress(0);
    axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateSaleEmiDetails/${newRow?.id}`, details, config).then((response) => {
      if (response.data.status) {
          toast.success(response.data.msg);
      }else{
          toast.error(response.data.msg);
      }

  })
  .catch(e => {
          //console.log(e);
  })
  
  
  }
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    editDataInDb(newRow)
    return updatedRow;
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  

  

  const columns = [
    {
      field: "emiNumber",
      headerName: "Emi Number",
      type: "number",
      width: 120,
      align: "left",
      headerAlign: "left",
    //   editable: true,
    },
    {
      field: "expectedDate",
      ...dateColumnType,
      headerName: "Expected Emi Date",
      // type: "date",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,

    },
    {
        field: "txnMode",
        headerName: "Txn Mode",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderEditCell: (params) => <GridEditSelectCell {...params} />,
        editableOptions: [
          { value: 'QR', label: 'QR' },
          { value: 'Link', label: 'Link' },
          { value: 'AccountTransfer', label: 'Account Transfer' },
          { value: 'JODO', label: 'JODO' },
          { value: 'ShopeSe', label: 'ShopeSe' },
        ],
      },
      {
        field: "txnDate",
      ...dateColumnType,
        headerName: "Txn Date",
        // type: "string",
        width: 160,
        align: "left",
        headerAlign: "left",
      editable: true,
        
      },
      {
        field: "txnId",
        headerName: "Txn Id",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
        editable: true,
  
      },
      {
        field: "txnAmount",
        headerName: "Txn Amount",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
        editable: true,
  
      },
      {
        field: "isPaid",
        headerName: "Paid Status",
        type: "boolean",
        width: 100,
        align: "left",
        headerAlign: "left",
        editable: true,
        renderCell: (params) => (
          <div style={{ color: params.value == true ? 'green' : 'red' }}>
            {params.value ? 'Paid' : 'Un Paid'}
          </div>
        ),
  
      },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <></>
          // (( ((access?.accessForTab == 'read'  || access?.accessForTab == 'readWrite') && access?.access?.includes('121'))  || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<VisibilityOutlinedIcon />}
          //   label="View"
          //   onClick={() => navigate(`/dashboard/student/emis/${id}`)}
          //   color="inherit"
          // />: <></>)
        ];
      },
    },
  ];

  

  return (
    <Box
      sx={{
        height: 500,
        // overflow:'hidden',
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}>
        <h2>Emi's Of User</h2>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DataGrid
        getRowHeight={() => 'auto'}
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
        // hideScrollbar={true}
      />
      </LocalizationProvider>
      
    </Box>
  );
}

import {
  Navigate,
  useRoutes,
} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard/index.js";
// import Blog from './pages/Blog';
import Login from "./pages/Login.js";
import NotFound from "./pages/Page404.js";
// import Products from './pages/Products';
import DashboardApp from "./pages/DashboardApp.js";
import getAccess from "./utils/getAccess.js";
import User1 from './pages/User1.js'
import StudentProfile from "./pages/StudentProfile.js";
import BatchProfile from "./pages/BatchProfile.js";
import EmisTable from "./pages/EmisTable.js";
import DashboardGrid from "./pages/DashboardGrid.js";




// ----------------------------------------------------------------------

export default function Router() {
  const d = getAccess();
  const role = d?.role == 'admin' ? "Admin" : d?.role;

  const LoggedIn = localStorage.getItem("isLoggedIn");
  const assignBatch = localStorage.getItem('assignBatch');
  // console.log(d)
  // console.log(assignBatch)
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      // eslint-disable-next-line no-sparse-arrays
      children: [
        { path: "app", element: <DashboardApp /> },
        {
          // path: "students", element: role !== 'Admin' && !d?.access?.includes('11') ? <Navigate to="/404" replace /> : <Student />
          path: "students", element: (!d?.access?.includes('all') && !d?.access?.includes('1111')) ? <Navigate to="/404" replace /> :  <User1 />

        },
        { path: "students/studentsProfile/:data", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <StudentProfile /> },
        { path: "student/batchProfile/:myBatchId", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <BatchProfile /> },
        { path: "student/emis/:myBatchId", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EmisTable /> },
        { path: "details/:type", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <DashboardGrid /> },
        


      ],
    },
    {
      path: "/",
      element: LoggedIn ? <Navigate to="/dashboard/app" replace /> : <Login />,
    },
    {
      path: "/404",
      element: <NotFound text='No Access' />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}

import "simplebar/src/simplebar.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { UserContext } from "./context/context";
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <HelmetProvider>
    <BrowserRouter>
      <UserContext>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={true} //If true, the library writes verbose logs to console.
          metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
         
          <App />
        </CacheBuster>
      </UserContext>
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();
reportWebVitals();

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import  toast  from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
// import getAccess from "../../../utils/getAccess";
import getAccess from "../utils/getAccess"
import axios from "axios";



export default function BatchProfile() {
    const { myBatchId } = useParams();
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const navigate = useNavigate();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/saleMyBatchDetails/${myBatchId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data ?? []) ;
    
    }

    fetchUsers();
  }, []);

  const editDataInDb = async (newRow) => {
    const details = {
       enrollAmount : newRow?.enrollAmount,
       totalAmountReceived : newRow?.totalAmountReceived,
       noOfEmi : newRow?.noOfEmi ,
   }
//    console.log(details)

  // setLoading(true)

  const token = localStorage.getItem("token");
  const authToken = token;
  const config = {
      headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${authToken}`,
      },
  };
//   setProgress(0);
    axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateSaleMyBatchdetails/${newRow?.id}`, details, config).then((response) => {
      if (response.data.status) {
          toast.success(response.data.msg);
      }else{
          toast.error(response.data.msg);
      }

  })
  .catch(e => {
          //console.log(e);
  })
  
  
  }
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    editDataInDb(newRow)
    return updatedRow;
  };
 

  

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

 

  const columns = [
   

    {
      field: "enrollDate",
      headerName: "Enroll Date",
      type: "string",
      width: 120,
      align: "left",
      headerAlign: "left",
    //   editable: true,
    },
    {
      field: "enrollAmount",
      headerName: "Enroll Amount",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,


    },
    {
        field: "totalAmountReceived",
        headerName: "Total Amount Received",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        editable: true,
  
      },
      {
        field: "pendingAmount",
        headerName: "Pending Amount",
        type: "string",
        width: 160,
        align: "left",
        headerAlign: "left",
  
      },
      {
        field: "noOfEmi",
        headerName: "No Of Emi",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
        editable: true,
      },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          (( ((access?.accessForTab == 'read'  || access?.accessForTab == 'readWrite') && access?.access?.includes('1111'))  || access?.access.includes('all')) ? <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            onClick={() => navigate(`/dashboard/student/emis/${id}`)}
            color="inherit"
          />: <></>)
        ];
      },
    },
  ];

  

  return (
    <Box
      sx={{
        height: 500,
        // overflow:'hidden',
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}>
        <h2>Batch's Profile</h2>
      </Box>
      <DataGrid
        getRowHeight={() => 'auto'}
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
        // hideScrollbar={true}
      />
    </Box>
  );
}
